import oAuth2ImplicitGrant from '@luigi-project/plugin-auth-oauth2'
import '../scss/luigi.scss'
import { getEnv } from '../commons'

$.notify.defaults({
    autoHideDelay: 9000,
    position: 'top center',
})

const tenant = getFromLocalStorage('tenant') || ''
const accessToken = getFromLocalStorage('access_token')
const userRole = getFromLocalStorage('currentUserRole')
const expires_in = getFromLocalStorage('expiresin')
const idToken = getFromLocalStorage('id_token')
const refreshToken = getFromLocalStorage('refresh_token')
const backofficeUserNumber = getFromLocalStorage('backofficeUserNumber')
const backofficeUserMail = getFromLocalStorage('backofficeUserMail')
const nonce = getFromLocalStorage('nonce')
const clients = JSON.parse(getFromLocalStorage('clients'))
const loginUrl =
    getEnv() === 'prod'
        ? 'https://backoffice-login.emporix.io'
        : 'https://stage-backoffice-login.emporix.io'
        // : 'http://localhost:4201'
const logoutUrl =
    getEnv() === 'prod'
        ? 'https://backoffice-login.emporix.io/logout'
        : 'https://stage-backoffice-login.emporix.io/logout'
        // : 'http://localhost:4201/logout'
const resetPasswordUrl =
    getEnv() === 'prod'
        ? 'https://authentication.emporix.io/ui/recovery'
        : 'https://auth-ory-stage.emporix.io/ui/recovery'
const loginRedirectUri = '/assets/auth/login.html'

localStorage.env = getEnv()

function getFromLocalStorage(key) {
    return localStorage.getItem(key)
}

function getRequestDomain() {
    return getEnv() === 'prod' ? 'api.emporix.io' : `api-stage.emporix.io`
}

function getAppLanguage() {
    const preferredLanguage = localStorage.getItem('preferredLanguage') || 'en-US'
    if (preferredLanguage.indexOf('de') > -1) {
        return 'de-DE'
    } else {
        return 'en-US'
    }
}

function getProjectCurr() {
    const currenciesData = [
        {
            id: 'GBP',
            label: 'British Pound Sterling',
            default: false,
            required: false,
        },
        {
            id: 'NOK',
            label: 'Norwegian Krone',
            default: false,
            required: false,
        },
        {
            id: 'USD',
            label: 'US Dollar',
            default: true,
            required: true,
        },
        {
            id: 'EUR',
            label: 'Euro',
            default: false,
            required: false,
        },
    ]

    if (localStorage.getItem('currencies')) {
        try {
            const currencies = JSON.parse(localStorage.getItem('currencies'))
            return currencies
        } catch (error) {
            console.log(error)
            return currenciesData
        }
    } else {
        return currenciesData
    }
}

function getTenantSwitcherOptions() {
    return clients
        ? clients
            .filter(client => client.tenant !== tenant)
            .map(client => {
                return {
                    label: client.tenant.toUpperCase(),
                    position: 'bottom',
                    clickHandler: function() {
                        localStorage.setItem('tenant', client.tenant)
                        const rawToken = localStorage.getItem('id_token')
                        const newToken = `${rawToken};${client.tenant}`
                        let luigiAuth = localStorage.getItem('luigi.auth')
                        luigiAuth = {
                            ...JSON.parse(luigiAuth),
                            accessToken: newToken,
                        }
                        localStorage.setItem('access_token', newToken)
                        localStorage.setItem('luigi.auth', JSON.stringify(luigiAuth))
                        location.reload()
                    },
                }
            })
        : {}
}

async function signOut() {
    localStorage.clear()
    window.location.replace(logoutUrl)
}

async function checkTokensValidity() {
    try {
        await checkUserAuthentication()
    } catch (error) {
        await signOut()
    }
}

async function checkUserAuthentication() {
    var url = ''
    const pathSuffix = `/configuration/${tenant}/configurations`
    const env = getContext().env
    if (env === 'dev') {
        url = 'https://api-stage.emporix.io' + pathSuffix
    } else if (env === 'prod') {
        url = 'https://api.emporix.io' + pathSuffix
    }
    const token = getFromLocalStorage('id_token')
    const response = await fetch(url, {
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Emporix-Tenant': tenant,
        },
    })

    if (response.status === 401) {
        throw new Error('unauthenticated')
    }
}

function getContext() {
    return {
        currentProjectId: tenant,
        tenant: tenant,
        env: getEnv(),
        accessToken: accessToken,
        refreshToken: refreshToken,
        globalSettings: {
            appRequestDomain: getRequestDomain(),
        },
        notificationConfig: {
            autoProcessing: true,
            autoNotifications: true,
        },
        currentWidget: {
            settings: {
                appId: tenant,
                _module_id: null,
            },
        },
        currentAccountId: backofficeUserMail,
        currentProjectPath: '/project',
        currentProjectLocales: {
            languages: [
                {
                    id: 'en',
                    label: 'English',
                    default: true,
                    required: true,
                },
            ],
            currencies: getProjectCurr(),
        },
        missingDependencies: [],
        helpMode: false,
        _hasBack: false,
        currentLanguage: getAppLanguage(),
        accessTokenExpDate: expires_in,
        currentUserRole: userRole,
        currentUserId: backofficeUserNumber,
        idToken: idToken,
        nonce,
        ...Luigi.auth(),
    }
}

const PROJECT_ROOT = 'project'

function getMpreisNodes() {
    return tenant.includes('mpreis')
        ? [
            {
                pathSegment: 'productModule',
                label: 'Product Module',
                viewUrl: process.env.local
                    ? 'http://localhost:4300/#/'
                    : `https://product-module-${getEnv()}.web.app/#/`,
                virtualTree: true,
                context: getContext(),
                icon: 'add-product',
            },
            {
                pathSegment: 'category-module',
                label: 'Category Module',
                viewUrl: process.env.local
                    ? 'http://localhost:4400/#/'
                    : `https://category-module-${getEnv()}.web.app/#/`,
                virtualTree: true,
                context: getContext(),
                icon: 'activities',
            },
            {
                pathSegment: 'location-module',
                label: 'Location Module',
                viewUrl: process.env.local
                    ? 'http://localhost:4500/#/'
                    : `https://location-module-${getEnv()}.web.app/#/`,
                virtualTree: true,
                context: getContext(),
                icon: 'functional-location',
            },
            {
                pathSegment: 'order-module',
                label: 'Order Module',
                viewUrl: process.env.local
                    ? 'http://localhost:8082/#'
                    : `https://order-module-${getEnv()}.web.app/#`,
                virtualTree: true,
                context: getContext(),
                icon: 'sales-quote',
            },
        ]
        : []
}

function getPicklistModule() {
    if (tenant.includes('mpreis')) {
        return `https://picklistadmin-v2-${getEnv()}.web.app`
    } else {
        return `https://picklistadmin-${getEnv()}.web.app`
    }
}

function getCustomerModule() {
    if (process.env.local) {
        return 'http://localhost:8080/#'
    }

    if (tenant.includes('mpreis') || tenant.includes('saastest2')) {
        return `https://customer-module-${getEnv()}.web.app/#`
    } else {
        return `https://customeradmin-${getEnv()}.web.app`
    }
}

function getMpreisChildren() {
    return [
        {
            category: {
                label: 'Order Fullfilment',
                icon: 'sales-quote',
                collapsible: true,
            },
            pathSegment: 'order-module',
            label: 'Order Module',
            viewUrl: process.env.local
                ? 'http://localhost:8082/#'
                : `https://order-module-${getEnv()}.web.app/#`,
            virtualTree: true,
            context: getContext(),
            icon: 'sales-quote',
        },
        {
            category: 'Order Fullfilment',
            pathSegment: 'picklists',
            label: 'Picklists',
            icon: 'activity-2',
            viewUrl: getPicklistModule(),
            context: getContext(),
        },
        {
            category: {
                label: 'Order Fullfilment',
                icon: 'sales-quote',
                collapsible: true,
            },
            pathSegment: 'orders',
            label: 'Orders',
            icon: 'sales-quote',
            viewUrl: `https://orderadmin-${getEnv()}.web.app`,
            context: getContext(),
        },
        {
            category: {
                label: 'Product Management',
                icon: 'add-product',
                collapsible: true,
            },
            pathSegment: 'productModule',
            label: 'Product Module',
            viewUrl: process.env.local
                ? 'http://localhost:4300/#/'
                : `https://product-module-${getEnv()}.web.app/#/`,
            virtualTree: true,
            context: getContext(),
            icon: 'add-product',
        },
        {
            category: 'Product Management',
            pathSegment: 'category-module',
            label: 'Category Module',
            viewUrl: process.env.local
                ? 'http://localhost:4400/#/'
                : `https://category-module-${getEnv()}.web.app/#/`,
            virtualTree: true,
            context: getContext(),
            icon: 'activities',
        },
        {
            category: 'Product Management',
            pathSegment: 'suppliers',
            label: 'Suppliers',
            icon: 'shipping-status',
            viewUrl: `https://supplierAdmin-${getEnv()}.web.app`,
            context: getContext(),
        },
        {
            category: 'Product Management',
            pathSegment: 'location-module',
            label: 'Location Module',
            viewUrl: process.env.local
                ? 'http://localhost:4500/#/'
                : `https://location-module-${getEnv()}.web.app/#/`,
            virtualTree: true,
            context: getContext(),
            icon: 'functional-location',
        },
        {
            category: {
                label: 'Marketing',
                icon: 'customer',
                collapsible: true,
            },
            pathSegment: 'customers',
            label: 'Customers',
            icon: 'customer',
            viewUrl: getCustomerModule(),
            context: getContext(),
            virtualTree: true,
        },
        {
            category: 'Marketing',
            pathSegment: 'store-admin-coupons',
            label: 'Coupons',
            icon: 'lead',
            viewUrl: `https://caasCouponAdmin-${getEnv()}.web.app/#!/coupons`,
            context: getContext(),
            keepSelectedForChildren: true,
            children: [
                {
                    pathSegment: 'store-admin-coupons-Details',
                    label: 'Coupon Details',
                    viewUrl: `https://caasCouponAdmin-${getEnv()}.web.app/#!/create-coupons`,
                    context: getContext(),
                    children: [
                        {
                            pathSegment: ':couponId',
                            label: 'Coupon Details2',
                            viewUrl: `https://caasCouponAdmin-${getEnv()}.web.app/#!/coupons/:couponId/`,
                            context: '{couponId:\':couponId\'}',
                        },
                    ],
                },
            ],
        },
        {
            category: 'Marketing',
            pathSegment: 'coupon-report',
            label: 'Coupon Reports',
            icon: 'monitor-payments',
            viewUrl: `https://couponAdmin-${getEnv()}.web.app`,
            context: getContext(),
        },
        {
            category: 'Marketing',
            pathSegment: 'grocery-reports',
            label: 'Grocery Reports',
            icon: 'manager-insight',
            viewUrl: `https://reportsAdmin-${getEnv()}.web.app`,
            context: getContext(),
        },
        {
            category: {
                label: 'Administration',
                icon: 'group',
                collapsible: true,
            },
            pathSegment: 'users',
            label: 'User Management',
            icon: 'group',
            viewUrl: `https://user-management-interface-${getEnv()}.web.app`,
            context: getContext(),
            keepSelectedForChildren: true,
            children: [
                {
                    pathSegment: 'user-details',
                    label: 'Create User',
                    viewUrl: `https://user-management-interface-${getEnv()}.web.app/create`,
                    context: getContext(),
                    children: [
                        {
                            pathSegment: ':id',
                            label: 'Edit User',
                            viewUrl: `https://user-management-interface-${getEnv()}.web.app/edit/:id/`,
                            context: getContext(),
                        },
                    ],
                },
            ],
        },
        {
            category: {
                label: 'Settings',
                icon: 'supplier',
                collapsible: true,
            },
            pathSegment: 'store-admin-shipping',
            label: 'Delivery Methods',
            icon: 'supplier',
            viewUrl: `https://sitemanagementadmin-${getEnv()}.web.app/#!/settings/shipping-settings/`,
            context: getContext(),
            keepSelectedForChildren: true,
            children: [
                {
                    pathSegment: 'store-admin-shipping-Details',
                    label: 'Delivery Methods Settings',
                    context: getContext(),
                    children: [
                        {
                            pathSegment: ':settingsId',
                            label: 'Delivery Methods Settings',
                            viewUrl: `https://sitemanagementadmin-${getEnv()}.web.app/#!/settings/shipping-settings/:settingsId/`,
                            context: getContext(),
                        },
                    ],
                },
            ],
        },
        {
            category: 'Settings',
            pathSegment: 'delivery-times',
            label: 'Delivery Times',
            icon: 'date-time',
            viewUrl: `https://deliverytimeadmin-${getEnv()}.web.app`,
            context: getContext(),
        },
        {
            category: 'Settings',
            pathSegment: 'fees',
            label: 'Additional Fees',
            icon: 'per-diem',
            viewUrl: `https://feeadmin-${getEnv()}.web.app`,
            context: getContext(),
        },
    ]
}

function getChildren() {
    const nodes = [
        {
            category: {
                label: 'Order Fullfilment',
                icon: 'sales-quote',
                collapsible: true,
            },
            pathSegment: 'orders',
            label: 'Orders',
            icon: 'sales-quote',
            viewUrl: `https://orderadmin-${getEnv()}.web.app`,
            context: getContext(),
        },
        {
            category: 'Order Fullfilment',
            pathSegment: 'picklists',
            label: 'Picklists',
            icon: 'activity-2',
            viewUrl: `https://picklistadmin-${getEnv()}.web.app`,
            context: getContext(),
        },
        {
            category: {
                label: 'Product Management',
                icon: 'add-product',
                collapsible: true,
            },
            pathSegment: 'products',
            label: 'Products',
            icon: 'add-product',
            viewUrl: `https://pcmadmin-${getEnv()}.web.app`,
            context: getContext(),
        },
        {
            category: 'Product Management',
            pathSegment: 'labels',
            label: 'Labels',
            icon: 'bar-code',
            viewUrl: `https://labelAdmin-${getEnv()}.web.app`,
            context: getContext(),
        },
        {
            category: 'Product Management',
            pathSegment: 'brands',
            label: 'Brands',
            icon: 'e-care',
            viewUrl: `https://brandadmin-${getEnv()}.web.app`,
            context: getContext(),
        },
        {
            category: 'Product Management',
            pathSegment: 'suppliers',
            label: 'Suppliers',
            icon: 'shipping-status',
            viewUrl: `https://supplierAdmin-${getEnv()}.web.app`,
            context: getContext(),
        },
        {
            category: {
                label: 'Marketing',
                icon: 'customer',
                collapsible: true,
            },
            pathSegment: 'customers',
            label: 'Customers',
            icon: 'customer',
            viewUrl: getCustomerModule(),
            context: getContext(),
            virtualTree: true,
        },
        {
            category: 'Marketing',
            pathSegment: 'content',
            label: 'Content',
            icon: 'curriculum',
            viewUrl: `https://contentAdmin-${getEnv()}.web.app`,
            context: getContext(),
        },
        {
            category: 'Marketing',
            pathSegment: 'store-admin-coupons',
            label: 'Coupons',
            icon: 'lead',
            viewUrl: `https://caasCouponAdmin-${getEnv()}.web.app/#!/coupons`,
            context: getContext(),
            keepSelectedForChildren: true,
            children: [
                {
                    pathSegment: 'store-admin-coupons-Details',
                    label: 'Coupon Details',
                    viewUrl: `https://caasCouponAdmin-${getEnv()}.web.app/#!/create-coupons`,
                    context: getContext(),
                    children: [
                        {
                            pathSegment: ':couponId',
                            label: 'Coupon Details2',
                            viewUrl: `https://caasCouponAdmin-${getEnv()}.web.app/#!/coupons/:couponId/`,
                            context: '{couponId:\':couponId\'}',
                        },
                    ],
                },
            ],
        },
        {
            category: 'Marketing',
            pathSegment: 'coupon-report',
            label: 'Coupon Reports',
            icon: 'monitor-payments',
            viewUrl: `https://couponAdmin-${getEnv()}.web.app`,
            context: getContext(),
        },
        {
            category: 'Marketing',
            pathSegment: 'grocery-reports',
            label: 'Grocery Reports',
            icon: 'manager-insight',
            viewUrl: `https://reportsAdmin-${getEnv()}.web.app`,
            context: getContext(),
        },
        {
            category: {
                label: 'Administration',
                icon: 'group',
                collapsible: true,
            },
            pathSegment: 'users',
            label: 'User Management',
            icon: 'group',
            viewUrl: process.env.local
                ? 'http://localhost:4800/'
                : `https://user-management-interface-${getEnv()}.web.app`,
            context: getContext(),
            keepSelectedForChildren: true,
            children: [
                {
                    pathSegment: 'user-details',
                    label: 'Create User',
                    viewUrl: process.env.local
                        ? 'http://localhost:4800/create'
                        : `https://user-management-interface-${getEnv()}.web.app/create`,
                    context: getContext(),
                    children: [
                        {
                            pathSegment: ':id',
                            label: 'Edit User',
                            viewUrl: process.env.local
                                ? 'http://localhost:4800/edit/:id/'
                                : `https://user-management-interface-${getEnv()}.web.app/edit/:id/`,
                            context: getContext(),
                        },
                    ],
                },
            ],
        },
        {
            category: {
                label: 'Settings',
                icon: 'supplier',
                collapsible: true,
            },
            pathSegment: 'store-admin-shipping',
            label: 'Delivery Methods',
            icon: 'supplier',
            viewUrl: `https://sitemanagementadmin-${getEnv()}.web.app/#!/settings/shipping-settings/`,
            context: getContext(),
            keepSelectedForChildren: true,
            children: [
                {
                    pathSegment: 'store-admin-shipping-Details',
                    label: 'Delivery Methods Settings',
                    context: getContext(),
                    children: [
                        {
                            pathSegment: ':settingsId',
                            label: 'Delivery Methods Settings',
                            viewUrl: `https://sitemanagementadmin-${getEnv()}.web.app/#!/settings/shipping-settings/:settingsId/`,
                            context: getContext(),
                        },
                    ],
                },
            ],
        },
        {
            category: 'Settings',
            pathSegment: 'delivery-areas',
            label: 'Delivery Areas',
            icon: 'map',
            viewUrl: `https://deliveryareaadmin-${getEnv()}.web.app`,
            context: getContext(),
        },
        {
            category: 'Settings',
            pathSegment: 'delivery-times',
            label: 'Delivery Times',
            icon: 'date-time',
            viewUrl: `https://deliverytimeadmin-${getEnv()}.web.app`,
            context: getContext(),
        },
        {
            category: 'Settings',
            pathSegment: 'fees',
            label: 'Additional Fees',
            icon: 'per-diem',
            viewUrl: `https://feeadmin-${getEnv()}.web.app`,
            context: getContext(),
        },
        {
            category: 'Settings',
            pathSegment: 'countries',
            label: 'Countries',
            icon: 'globe',
            viewUrl: `https://countryadmin-${getEnv()}.web.app`,
            context: getContext(),
        },
        {
            category: 'Settings',
            pathSegment: 'commerceSettings',
            label: 'Algolia Search',
            icon: 'detail-view',
            keepSelectedForChildren: true,
            children: [
                {
                    pathSegment: 'algolia_module',
                    label: 'Algolia Search Settings',
                    viewUrl: `https://algoliamodule-${getEnv()}.web.app/#!/algolia-settings/`,
                    context: getContext(),
                },
            ],
        },
        {
            category: 'Order Fullfilment',
            pathSegment: 'sepa-export',
            label: 'SEPA Export',
            viewUrl: process.env.local
                ? 'http://localhost:4600/#/'
                : `https://sepa-module-${getEnv()}.web.app/#/`,
            virtualTree: true,
            context: getContext(),
            icon: 'functional-location',
        },
        ...getMpreisNodes(),
    ]
    if (tenant.includes('struve') || tenant.includes('lb1edekamull') || getEnv() === 'dev') {
        nodes.push({
            category: 'Product Management',
            pathSegment: 'category-module',
            label: 'Category Module',
            viewUrl: process.env.local
                ? 'http://localhost:4400/#/'
                : `https://category-module-${getEnv()}.web.app/#/`,
            virtualTree: true,
            context: getContext(),
            icon: 'activities',
        })
    }

    return nodes
}

const configuration = {
    auth: {
        use: 'authConfig',
        authConfig: {
            idpProvider: oAuth2ImplicitGrant,
            authorizeUrl: loginUrl,
            oAuthData: {
                redirect_uri: loginRedirectUri,
            },
            logoutFn: () => {
                signOut()
            },
            userInfoFn: () => {
                checkTokensValidity()
                return new Promise(resolve => {
                    resolve({
                        name: `
                        ${localStorage.getItem('first_name')}
                        ${localStorage.getItem('last_name')}
                        `,
                    })
                })
            },
        },
    },
    navigation: {
        profile: {
            logout: {
                label: 'Logout',
                icon: 'log',
            },
            items: [
                {
                    label: 'Password',
                    icon: 'user-edit',
                    externalLink: {
                        url: resetPasswordUrl,
                        sameWindow: false,
                    },
                },
            ],
        },
        nodes: [
            {
                pathSegment: PROJECT_ROOT,
                label: 'Project',
                navigationContext: 'projectContext',
                hideFromNav: true,
                children: tenant.includes('mpreis')
                    ? getMpreisChildren()
                    : getChildren(),
            },
        ],
    },
    routing: {
        useHashRouting: true,
    },
    settings: {
        header: { logo: './assets/images/emporix-logo-header.svg' },
        responsiveNavigation: 'Fiori3',
        appLoadingIndicator: {
            hideAutomatically: true,
        },
        customSandboxRules: ['allow-downloads'],
    },
    lifecycleHooks: {
        luigiAfterInit: () => {
            const intervalTimeInMinutes = 3
            window.setInterval(() => {
                checkTokensValidity()
            }, intervalTimeInMinutes * 60000)
        },
    },
}

if (getTenantSwitcherOptions().length > 0) {
    configuration.navigation.contextSwitcher = {
        defaultLabel: tenant.toUpperCase(),
        parentNodePath: '/environment',
        options: [{ label: tenant.toUpperCase() }],
        customOptionsRenderer: () => '',
        actions: getTenantSwitcherOptions(),
    }
} else {
    configuration.navigation.nodes.push({
        pathSegment: tenant,
        testId: 'tenant',
        label: tenant.toUpperCase(),
        onNodeActivation: () => false,
    })
}

Luigi.setConfig(configuration)
